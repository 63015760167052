<template>
	<div class="box">
    <span class="title">{{$store.getters.IsLogin ? $t('topbar.changePassword') : $t('auth.findpassword')}}</span>
    <span class="subtitle" v-show="!$store.getters.IsLogin">
      <router-link to="/auth/register">{{$t('auth.toRegister')}}</router-link>
      <span class="divide">|</span>
      <router-link to="/auth/login">{{$t('auth.toLogin')}}</router-link>
    </span>
		<el-form class="form" :model="ruleForm" :rules="rules" ref="ruleForm">
      <el-form-item prop="email" v-show="!$store.getters.IsLogin">
        <el-input class="input" v-model="ruleForm.email" type="text" :placeholder="$t('auth.mail')"/>
      </el-form-item>
      <el-form-item prop="verification">
        <el-input class="input" v-model="ruleForm.verification" type="text" :placeholder="$t('auth.verification')"> 
          <el-button slot="append" :disabled="isVerificationButtonDisabled || (!$store.getters.IsLogin && ruleForm.email === '')" @click.prevent="sendForVerification">{{verificationButtonText}}</el-button>
        </el-input>
      </el-form-item>
      <el-form-item prop="oldPassword" v-show="$store.getters.IsLogin">
        <el-input class="input" v-model="ruleForm.oldPassword" type="text" :placeholder="$t('auth.oldPassword')" show-password/>
      </el-form-item>
      <el-form-item prop="newPassword">
        <el-input class="input" v-model="ruleForm.newPassword" type="text" :placeholder="$t('auth.newPassword')" show-password/>
      </el-form-item>
      <el-form-item>
        <Debounce :time='1000' !isDebounce :isShowRepeatedSubmitMessage="showRepeatedSubmit">
          <button class="button" style="width:95%;margin-bottom:0px;" @click.prevent="submitForm('ruleForm')">{{$t('general.submit')}}</button>
        </Debounce>
      </el-form-item>
    </el-form>
	</div>
</template>

<script>
import { registerCheckEmailExist, findPasswordVerification, findPassword } from '../../network/auth'

export default ({
  name: 'FindPassword',
	data() {
		// const checkEmail = this.checkEmail
		return {
			ruleForm: {
				email: '',
				verification: '',
				oldPassword: '',
				newPassword: '',
			},
			rules: {
        email: [{validator: this.checkEmail, trigger: 'blur'}],
        // verification: [{validator: this.checkVerification, trigger: 'blur'}],
        verification: [{required: true, message: this.$t('auth.pleaseInputVerificationCode'), trigger: 'change'}],
        oldPassword: [{validator: this.checkOldPassword, trigger: 'blur'}],
        newPassword: [{validator: this.checkNewPassword, trigger: 'blur'}],
      },
      isVerificationButtonDisabled: false,
      count: 60,
      verificationButtonText: this.$t('auth.getVerification'),
      showRepeatedSubmit: false,
		}
	},
	methods: {
    checkEmail(rule, value, callback) {
      if (this.$store.getters.IsLogin) {
        callback()
      }
      if (value === '') {
        return callback(new Error(this.$t('auth.pleaseInputMail')));
      } 
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
      if (!regEmail.test(value)) {
        return callback(new Error(this.$t('auth.pleaseInputValidMail')))
      }
      registerCheckEmailExist(value).then( val => {
          // console.log(val.data)
          if (!val.data) {
            callback()
          } else {
            callback(new Error(this.$t('auth.emailNoAccount')))
          }
        }
      )
    },
    // checkVerification(rule, value, callback) {
    //   if (value === '') {
    //     return callback(new Error('请输入验证码'));
    //   }
    //   callback()
    // },
    checkOldPassword(rule, value, callback) {
      if (!this.$store.getters.IsLogin) {
        return callback()
      }
      if (value === '') {
        return callback(new Error(this.$t('auth.pleaseInputOldPassword')));
      }
      callback()
    },
    checkNewPassword(rule, value, callback) {
      if (value === '') {
        return callback(new Error(this.$t('auth.pleaseInputNewPassword')));
      }
      if (this.$store.getters.IsLogin && this.ruleForm.oldPassword === this.ruleForm.newPassword) {
        return callback(new Error(this.$t('auth.passwordTheSame')))
      }
      callback()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        this.showRepeatedSubmit = valid;
        if (valid) {
          findPassword(this.ruleForm.email, this.ruleForm.verification.trim(), this.ruleForm.oldPassword.trim(), this.ruleForm.newPassword.trim()).then(val => {
            this.$message({
              offset: 65,
              showClose: true,
              duration: 2000,
              message: val.data || val.message,
            });
            this.$router.push(this.$store.getters.IsLogin ? '/dashboard/home' : '/auth/login')
          }, response => {
            this.$message({
              message: response.data.message,
              offset: 65,
              showClose: true,
              duration: 3000,
            });
          })
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    sendForVerification() {
      this.$refs['ruleForm'].validateField('email', error => {
        if (error === '') {
          this.isVerificationButtonDisabled = true
          this.count = 60
          this.verificationButtonText = this.count + this.$t('auth.seconds')
          let times = setInterval(() => {
            this.count--
            this.verificationButtonText = this.count + this.$t('auth.seconds')
            if (this.count <= 0) {
              this.isVerificationButtonDisabled = false;
              this.verificationButtonText = this.$t('auth.getVerification')
              clearInterval(times);
            }
          }, 1000);
          findPasswordVerification(this.ruleForm.email).then(val => {
            this.$message({
              message: this.$t('auth.verificationMailSent'),
              offset: 65,
              showClose: true,
              duration: 2000,
            });
          }, response => {
            this.$message({
              message: response.data.message,
              offset: 65,
              showClose: true,
              duration: 2000,
            });
          })
        }
      })
      
    }
	},
})
</script>

<style scoped>
.box {
  /* height: 400px; */
  width: 400px;
  max-width: 80vw;
  /* min-height: 200px; */
  /* background-color: rgb(255, 255, 255); */
  /* border: 1px solid rgb(52, 52, 52); */
  box-sizing: border-box;
  /* box-shadow: rgb(0 0 0 / 12%) 0px 4px 4px, rgb(0 0 0 / 24%) 0px 16px 32px; */
  /* border-radius: 8px; */
  color: rgb(17, 17, 17);
  display: grid;
  grid-template-columns: 50% 50%;
  overflow: hidden;
  margin: auto;
  box-sizing: border-box;
  padding: 32px;
  display: block;
  /* vertical-align: middle; */
}
.form {
	margin-top: 10px;
}
.button {
  font-size: 1em;
  background-color: rgb(255, 255, 255);
  /* box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px; */
  color: rgb(4, 4, 4);
  height: auto;
  padding: 12px 24px;
  justify-content: flex-start;
  /* margin: 12px; */
  border-radius: 4px;
  transition: all 200ms ease 0s;
  cursor: pointer;
}
.title {
  font-size: 23px;
  text-align: left;
  font-weight: 700;
  line-height: normal;
  display: block;
  color: inherit;
  box-sizing: border-box;
  padding-bottom: 4px;
}
.subtitle {
  font-size: 13px;
  text-align: left;
  line-height: normal;
  display: block;
  color: rgb(153, 153, 153);
  box-sizing: border-box;
  padding-bottom: 4px;
}
.divide {
  margin: auto 15px;
}
</style>