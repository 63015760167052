<template>
  <div id="add-workspace" v-show="isAddWorkspaceShow">
		<div class="mask" @click="isAddWorkspaceShow=false"></div>
    <div class="box">
        <span class="title">{{$t('workspace.create')}}</span>
        <span class="return" @click="isAddWorkspaceShow=false">back</span>
		<el-form class="form" :model="ruleForm" :rules="rules" ref="ruleForm">
			<el-form-item prop="title">
				<el-input class="input" v-model="ruleForm.title" type="text" placeholder="name"/>
			</el-form-item>
			<el-form-item prop="template">
				<el-select class="input" v-model="ruleForm.template" placeholder="version">
					<el-option
						v-for="item in templates"
						:key="item.id"
						:label="item.template_name"
						:value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item prop="description">
				<el-input
					type="textarea"
					class="input"
					v-model="ruleForm.description"
					:rows="3"
					placeholder="description">
				</el-input>
			</el-form-item>
			<el-form-item>
				<Debounce :time='1000' !isDebounce :isShowRepeatedSubmitMessage="showRepeatedSubmit">
					<button class="button" @click.prevent="submitForm('ruleForm')">Create</button>
				</Debounce>
			</el-form-item>
		</el-form>
    </div>
  </div>
</template>

<script>
import { getAllTemplates } from '../network/dashborad'

export default ({
    name: 'AddWorkspace',
		data() {
			return {
				isAddWorkspaceShow: false,
				templates: [{id:0, template_name: ''}],
				ruleForm: {
					title: '',
					template: '',
					description: '',
				},
				rules: {
					title: [{required: true, message: this.$t('workspace.pleaseInputName'), trigger: 'blur'}, { max: 20, message: this.$t('workspace.pleaseInputNameLength'), trigger: 'blur' }],
					template: [{required: true, message: this.$t('workspace.pleaseSelectTemplate'), trigger: 'change'}],
					description: [{required: true, message: this.$t('workspace.pleaseInputDesc'), trigger: 'blur'}, { max: 100, message: this.$t('workspace.pleaseInputDescLength'), trigger: 'blur' }],
				},
				showRepeatedSubmit: false,
			}
		},
		// props: {
		// 	isShow: {type: Boolean, default: false}
		// },
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					this.showRepeatedSubmit = valid;
					if (valid) {
						this.$bus.$emit('CreateWorkspace', {
							title: this.ruleForm.title,
							id: this.ruleForm.template,
							description: this.ruleForm.description,
						})
						this.$refs[formName].resetFields()
						this.isAddWorkspaceShow = false
					} else {
						// console.log('error submit!!');
						return false;
					}
				});
			}
		},
		// watch: {
		// 	isShow(val) {
		// 		this.isAddWorkspaceShow = val;
		// 	}
		// },
		created() {
			getAllTemplates().then(val => {
				// console.log(val)
				val && (this.templates = val.data);
			}, error => {
				console.log(error)
			})
		},
		mounted() {
			this.$bus.$off('ShowAddWorkspace')
			this.$bus.$on('ShowAddWorkspace', () => this.isAddWorkspaceShow = true)
		}
})
</script>

<style scoped>
#add-workspace {
	/* width: 100vw; */
	height: 460px;
	position: fixed;
	padding: 0px;
	max-width: 400px;
	inset: 20vh 0px 40px;
	margin: 0px auto;
	font-family: "Inter", sans-serif;
	outline: currentcolor none medium;
	/* margin-bottom: 100px; */
	display: flex;
	/* flex-direction: row; */
	justify-content: center;
	align-items: center;
	border: 1px solid rgb(36, 36, 36);
	border-radius: 8px;
	background-color: rgb(21, 21, 21);
	box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 4px, rgba(0, 0, 0, 0.24) 0px 16px 32px;
	color: rgb(230, 230, 230);
	line-height: 1.2;
}
.mask {
	position: fixed;
	inset: 0px;
	background-color: rgba(0, 0, 0, 0.75);
	overflow-y: auto;
	z-index: 10;
	transform: translate3d(0px, 0px, 0px);
}
.box {
    /* height: 400px; */
	width: 400px;
	max-width: 80vw;
	/* min-height: 200px; */
	/* background-color: rgb(255, 255, 255); */
	/* border: 1px solid rgb(52, 52, 52); */
	box-sizing: border-box;
	/* box-shadow: rgb(0 0 0 / 12%) 0px 4px 4px, rgb(0 0 0 / 24%) 0px 16px 32px; */
	/* border-radius: 8px; */
	/* color: rgb(17, 17, 17); */
	display: grid;
	grid-template-columns: 50% 50%;
	overflow: hidden;
	margin: auto;
  box-sizing: border-box;
  padding: 32px;
  display: block;
	/* position: fixed; */
  /* vertical-align: middle; */
  /* display: flex; */
	/* min-width: 870px; */
	/* max-width: 1200px; */
	height: 456px;
	/* overflow: hidden; */
	border: 1px solid rgb(36, 36, 36);
	border-radius: 4px;
	background-color: rgb(240, 240, 240);
	color: rgb(255, 255, 255);
	box-shadow: rgba(0, 0, 0, 0.24) 0px 4px 4px;
	z-index: 11;
}
.title {
  font-size: 23px;
	text-align: left;
	font-weight: 700;
	line-height: normal;
	display: block;
	color: black;
	box-sizing: border-box;
	padding-bottom: 4px;
}
.return {
  font-size: 13px;
  text-align: left;
  line-height: normal;
  display: block;
  color: rgb(153, 153, 153);
  box-sizing: border-box;
  padding-bottom: 10px;
  padding-top: 10px;
	cursor: pointer;
}
.form {
	margin-top: 10px;
}
.input {
  margin-bottom: 10px;
	background-color: rgb(240, 240, 240);
  width: 100%;
}
.button {
	font-size: 1em;
	background-color: rgb(240, 240, 240);
	box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px;
	color: rgb(4, 4, 4);
	height: auto;
	padding: 10px 20px;
	justify-content: flex-start;
	margin: 12px;
	border-radius: 4px;
	transition: all 200ms ease 0s;
	cursor: pointer;
	width: 90%;
}
</style>