<template>
  <div>
    <h1>Libraries</h1>
    <!-- <p class="description">Here are collections of proof libraries.</p> -->
    <p class="description"><a href="http://afp.theoremproving.org/" target="_blank" style="color: #315efb;">AFP</a>{{$t('library.description')}} <code>imports "Abs_Int_ITP2012.Abs_Int3"</code>。</p>
    <!-- <el-divider></el-divider>
    <div>
      <div v-for="(lib,i) in libList" :key="i" class="item-text">
        <h5 v-if="lib.link === ''">{{lib.library_name}}</h5>
        <h5 v-else><a :href="lib.link" target="_blank">{{lib.library_name}}</a></h5>
        <p class="item-description">{{lib.description}}</p>
      </div>
    </div> -->
    <el-divider></el-divider>
    <div class="box">
      <div v-for="(tier1,key1) in libList" :key="'tier1'+key1">
        <h2 class="h2" v-if="Object.keys(tier1).length > 0">{{key1}}</h2>
        <div v-if="Object.keys(tier1).length > 0">
          <div v-for="(tier2,key2) in tier1" :key="'tier2'+key2">
            <h3 class="h3" v-if="tier2.length > 0">{{key2}}</h3>
            <el-table v-if="tier2.length > 0"
              stripe
              :data="tier2"
              >
              <el-table-column
                prop="session"
                label="session"
                sortable
                min-width="40%">
                <template slot-scope="scope">
                  <el-popover class="tooltip" 
                    trigger="hover"
                    width="auto"
                    placement="top-start">
                    <span v-html="scope.row.abstract" style="white-space: pre-wrap;"></span>
                    <a
                      slot="reference"
                      :href="'http://afp.theoremproving.org/entries/' + scope.row.session.toLowerCase()" target="_blank" style="color: #315efb;">{{scope.row.session}}</a>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column
                prop="title"
                label="title"
                sortable
                min-width="60%">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>    
</template>

<script>
// import { getLibList } from '../../network/library'
import axios from 'axios'

export default {
  name: 'Library',
  data() {
    return {
      libList: {},
      tableData: [
        {session: 'MSO_Regex_Equivalence', title: 'Decision Procedures for MSO on Words Based on Derivatives of Regular Expressions'},
        {session: 'Complete_Non_Orders', title: 'Complete Non-Orders and Fixed Points'},
      ]
    }
  },
  methods: {
    getLibList() {
      // getLibList().then(val => {
      //   val = val || []
      //   let libList = {}
      //   val.forEach(item => {
      //     let topics = item.topics || ['Others/Others']
      //     topics.forEach(topic => {
      //       let [tier1, tier2] = topic.split('/', 2)
      //       libList[tier1] === undefined && (libList[tier1] = {})
      //       libList[tier1][tier2] === undefined && (libList[tier1][tier2] = [])
      //       libList[tier1][tier2].push(item)
      //     })
      //   });
      //   this.libList = libList || {}
      // }, response => {
      //   console.log('getLibList:', response);
      // })
      axios.get('../../../metadata.json').then(res => {   
        // console.log(res)
        let val = res.data || []
        let libList = {}
        val.forEach(item => {
          let topics = item.topics || ['Others/Others']
          topics.forEach(topic => {
            let [tier1, tier2] = topic.split('/', 2)
            libList[tier1] === undefined && (libList[tier1] = {})
            libList[tier1][tier2] === undefined && (libList[tier1][tier2] = [])
            libList[tier1][tier2].push(item)
          })
        });
        this.libList = libList || {}
      }, error => {
        console.log(error)
      })
    }
  },
  created() {
    this.getLibList()
  }
}
</script>

<style lang="scss" scoped>
h1 {
  margin-top: 10px;
  padding: .5rem 0;
  // margin: 0;
    // margin-top: 0px;
  clear: both;
  line-height: 2.75rem;
}
.description {
  line-height: 1.35em;
  font-size: 1.35em;
  margin: auto;
  width: 80%;
}
.item-text {
  line-height: 1.35em;
  width: 100%;

  h5 {
    font-size: initial;
    display: initial;
  }

  a {
    color: #0645ad;
    text-decoration: none;
    font-weight: 700;
  }

  .item-description {
    line-height: 1em;
  }
}
.box {
  width: 80%;
  margin: auto;
  .h2 {
    margin: 1.5em 0 0;
    padding: 0;
    line-height: normal;
    text-align: left;
  }

  .h3 {
    margin: 1.5em 0 0;
    font-stretch: normal;
    font-size: larger;
    padding: 0;
    font-weight: 300;
    font-family: open sans,sans-serif;
    line-height: 1.35em;
    text-align: left;
  }
}

</style>