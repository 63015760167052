<template>
  <div id="auth">
    <div class="box login-box" v-show="!isRegister">
      <span class="title">{{$t('topbar.login')}}</span>
      <span class="subtitle">
        <router-link to="/auth/register">{{$t('auth.toRegister')}}</router-link>
        <span class="divide">|</span>
        <router-link to="/auth/findpassword">{{$t('auth.findpassword')}}</router-link>
      </span>
      <el-form class="form" :model="loginRuleForm" :rules="loginRules" ref="loginRuleForm">
        <el-form-item prop="identity">
          <el-input class="input" v-model="loginRuleForm.identity" type="text" :placeholder="$t('auth.name')+'/'+$t('auth.mail')"/>
        </el-form-item>
        <el-form-item prop="password">
          <el-input class="input" v-model="loginRuleForm.password" type="text" :placeholder="$t('auth.password')" show-password/>
        </el-form-item>
        <el-form-item>
          <!-- <el-button :loading="loading" type="primary" style="width:100%;margin-bottom:30px;" @click.native.prevent="handleLogin">Login</el-button> -->
          <Debounce :time='1000' !isDebounce :isShowRepeatedSubmitMessage="showRepeatedSubmit">
            <button class="button" style="width:95%;margin-bottom:0px;" @click.prevent="submitForm('loginRuleForm')">{{$t('topbar.login')}}</button>
          </Debounce>
        </el-form-item>
      </el-form>
    </div>
    <div class="box register-box" v-show="isRegister">
      <span class="title">{{$t('topbar.register')}}</span>
      <span class="subtitle">
        <router-link to="/auth/login">{{$t('auth.toLogin')}}</router-link>
        <span class="divide">|</span>
        <router-link to="/auth/findpassword">{{$t('auth.findpassword')}}</router-link>
      </span>
      <el-form class="form" :model="registerRuleForm" :rules="registerRules" ref="registerRuleForm">
        <el-form-item prop="username">
          <el-input class="input" v-model="registerRuleForm.username" type="text" :placeholder="$t('auth.name')"/>
        </el-form-item>
        <el-form-item prop="email">
          <el-input class="input" v-model="registerRuleForm.email" type="text" :placeholder="$t('auth.mail')"/>
        </el-form-item>
        <el-form-item prop="password">
          <el-input class="input" v-model="registerRuleForm.password" type="text" :placeholder="$t('auth.password')" show-password/>
        </el-form-item>
        <el-form-item>
          <Debounce :time='1000' !isDebounce :isShowRepeatedSubmitMessage="showRepeatedSubmit">
            <button class="button" style="width:95%;margin-bottom:0px;" @click.prevent="submitForm('registerRuleForm')">{{$t('topbar.register')}}</button>
          </Debounce>
        </el-form-item>
      </el-form>
    </div>
    <!-- <div v-show="isWaitingActivate">
      <h1>注册成功！<br/>一封激活邮件已经发送到{{inputEmail}}，请进行邮箱验证。</h1>
    </div> -->
    <router-view></router-view>
    <!-- </div> -->
    <!-- <div id="fake-footer"></div> -->
    <!-- <el-row> -->
      <!-- <el-col :span="24"> -->
        <!-- <div>
          <span></span>
          <el-input type="text"/>
          <el-input type="text"/>
        </div> -->
      <!-- </el-col> -->
    <!-- </el-row> -->
  </div>
</template>

<script>
// import TopBar from '../../components/TopBar.vue'
// import e from 'express';
import { login, register, registerCheckUsernameExist, registerCheckEmailExist } from '../../network/auth'
// import _ from 'lodash'

export default {
  name: "Auth",
  // components: { TopBar }
  data() {
    const checkUsername = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error(this.$t('workspace.pleaseInputName')));
      }
      if (value.length > 20) {
        return callback(new Error(this.$t('workspace.pleaseInputNameLength')));
      }
      registerCheckUsernameExist(value).then( val => {
          if (val.data) {
            callback()
          } else {
            callback(new Error(this.$t('auth.usernameExists')))
          }
      }, error => {callback();})
    }
    const checkEmail = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error(this.$t('auth.pleaseInputMail')));
      } 
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
      if (!regEmail.test(value)) {
        return callback(new Error(this.$t('auth.pleaseInputValidMail')))
      }
      registerCheckEmailExist(value).then( val => {
          // console.log(val.data)
          if (val.data) {
            callback()
          } else {
            callback(new Error(this.$t('auth.mailExists')))
          }
      }, error => {callback();})
    }
    return {
      // isRegister: false
      loginRuleForm: {
        identity: '',
        password: '',
        // description: '',
      },
      loginRules: {
        // identity: [{required: true, message: 'Please input password', trigger: 'blur'}, { max: 20, message: this.$t('workspace.pleaseInputNameLength'), trigger: 'blur' }],
        identity: [{required: true, trigger: 'blur'}, { max: 20, trigger: 'blur' }],
        // password: [{required: true, message: this.pleaseInputPassword, trigger: 'change'}],
        password: [{required: true, trigger: 'change'}],
      },
      registerRuleForm: {
        username: '',
        email: '',
        password: '',
        // description: '',
      },
      registerRules: {
        username: [{validator: checkUsername, trigger: 'blur'}],
        email: [{validator: checkEmail, trigger: 'blur'}],
        // password: [{required: true, message: '请输入密码', trigger: 'change'}],
        password: [{required: true, trigger: 'change'}],
      },
      showRepeatedSubmit: false,
      // inputEmail: "",
      // isWaitingActivate: false
    }
  },
  computed: {
    isRegister() { return this.$route.params.operation === 'register' ? true : false; },
    pleaseInputPassword() { return this.$t('auth.pleaseInputPassword') }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        this.showRepeatedSubmit = valid;
        if (valid) {
          if (this.isRegister) {
            register(this.registerRuleForm.username, this.registerRuleForm.email, this.registerRuleForm.password).then(val => {
              this.$message({
                offset: 65,
                showClose: true,
                duration: 2000,
                message: this.$t('auth.mailAwaitActivation'),
              });
              // this.inputEmail = val
              // this.isWaitingActivate = true
              // this.$router.push('/auth/active')
            }, response => {
              this.$message({
                message: response.data.message,
                offset: 65,
                showClose: true,
                duration: 3000,
              });
            })
          } else {
            login(this.loginRuleForm.identity, this.loginRuleForm.password).then(val => {
              this.$store.commit('user/WriteLogin', val.data)
              // this.$message('登录成功');
              let url = this.$route.query.redirect || '/dashboard'
              this.$router.push({
                path: decodeURIComponent(url)
              })
            }, response => {
              this.$message({
                message: response.data.message,
                offset: 65,
                showClose: true,
                duration: 2000,
              });
            })
          }
        } else {
          return false;
        }
      });
    }
  },  
}
</script>

<style scoped>
#auth {
    /* width: 100vw; */
  height: 99%;
  /* margin-bottom: 100px; */
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;

    /* box-sizing: border-box; */
    /* position: absolute; */
    /* left:50%;top:50%;transform:translate(-50%,-50%); */
  /* vertical-align: middle; */
  /* height: 400px; */
    /* width: 670px; */

}
.box {
  /* height: 400px; */
  width: 400px;
  max-width: 80vw;
  /* min-height: 200px; */
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(52, 52, 52);
  box-sizing: border-box;
  box-shadow: rgb(0 0 0 / 12%) 0px 4px 4px, rgb(0 0 0 / 24%) 0px 16px 32px;
  border-radius: 8px;
  color: rgb(17, 17, 17);
  display: grid;
  grid-template-columns: 50% 50%;
  overflow: hidden;
  margin: auto;
  box-sizing: border-box;
  padding: 32px;
  display: block;
  /* vertical-align: middle; */
}
.register-box {
  height: 400px;
}
.login-box {
  height: 320px;
}
.title {
  font-size: 23px;
  text-align: left;
  font-weight: 700;
  line-height: normal;
  display: block;
  color: inherit;
  box-sizing: border-box;
  padding-bottom: 4px;
}
.subtitle {
  font-size: 13px;
  text-align: left;
  line-height: normal;
  display: block;
  color: rgb(153, 153, 153);
  box-sizing: border-box;
  padding-bottom: 4px;
}
.form {
	margin-top: 10px;
}
.input {
  margin-bottom: 10px;
  width: 320px;
}
.button {
  font-size: 1em;
  background-color: rgb(255, 255, 255);
  /* box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px; */
  color: rgb(4, 4, 4);
  height: auto;
  padding: 12px 24px;
  justify-content: flex-start;
  /* margin: 12px; */
  border-radius: 4px;
  transition: all 200ms ease 0s;
  cursor: pointer;
}
.divide {
  margin: auto 15px;
}
</style>