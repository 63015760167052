<template>
  <div id="app" @click="$bus.$emit('HideTopBarUserMenu')">
    <!-- <el-container> -->
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
      <!-- <el-header> -->
        <TopBar/>
        <!-- </el-header> -->
      <!-- <el-main id="main"> -->
        <router-view></router-view>
        <Foot/>
      <!-- </el-main> -->
    <!-- </el-container> -->
    <!-- <VueSimpleContextMenu
      element-id="myUniqueId"
      :options="[{name: 'help'}]"
      ref="vueSimpleContextMenu"
      @option-clicked="optionClicked"
    /> -->
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import TopBar from './components/TopBar.vue'
import Foot from './components/Foot.vue'
// import VueSimpleContextMenu from 'vue-simple-context-menu'
// import 'vue-simple-context-menu/dist/vue-simple-context-menu.css';

export default {
  name: 'App',
  components: {
    // HelloWorld,
    TopBar,
    Foot,
    // VueSimpleContextMenu,
  }
}
</script>

<style>
/* @import "assets/css/base.css"; */
@import "assets/css/normalize.css";
html, body {
  height:100%;
  background: rgb(250, 250, 250);
  color: #040404;
  user-select: auto;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: calc(100% - 48px);
}
img {
  max-width: 100%;
}
</style>
