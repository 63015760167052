<template>
	<div id="auth">
    <h1>未来的比赛</h1>
    <div v-for="(compe, i) in futureCompetitions" :key="'fu'+i">{{compe}} <i v-show="IsOngoing(compe)">Ongoing</i></div>
    <h1>历史比赛</h1>
    <div v-for="(compe, i) in pastCompetitions" :key="'pa'+i">{{compe}}</div>
		<el-button @click="enroll">Enroll</el-button>
		<el-button @click="quit">Quit</el-button>
		<el-button @click="participate">Participate</el-button>
		<el-button @click="test">测试</el-button>
	</div>
</template>

<script>
import { getCompetitionList, enroll, quit, participate } from '../../network/competition'
import { Loading } from 'element-ui'

export default ({
	name: "CompetitionHome",
	data () {
		return {
			competitionList: []
		}
	},
	methods: {
	getCompetitionList() {
    getCompetitionList().then(val => {
        // console.log("val", val)
        // console.log(val.data[0].begin_time < new Date())
        this.competitionList = val.data || []
        // const {href} = this.$router.resolve({path: '/study-content', query:{id:1}})
        // open(href, "_blank")
      }, response => {
        this.$message({
          message: response.data.message,
          offset: 65,
          showClose: true,
          duration: 2000,
        });
      })
    },
		redirectToLogin(response) {
			if (response.status == 401) {
				this.$router.push({
					path: '/auth/login',
					query: {
						redirect: this.$router.currentRoute.fullPath
					}
				})
				this.$message({
          message: this.$t('auth.pleaseLogin'),
          offset: 65,
          showClose: true,
          duration: 2000,
        });
			} else{
				this.$message({
          message: response.data.message,
          offset: 65,
          showClose: true,
          duration: 2000,
        });
			}
		},
		enroll (competition_id) {
			enroll(competition_id).then(val => {
				console.log(val)
				this.$message(val.message)
			}, response => {
				this.redirectToLogin(response)
			})
		},
		quit (competition_id) {
			quit(competition_id).then(val => {
				console.log(val)
				this.$message(val.message)
			}, response => {
				this.redirectToLogin(response)
			})
		},
		participate(competition_id, index) {
      let loadingInstance = Loading.service({
        fullscreen: true
      })
      participate(competition_id).then(val => {
        setTimeout(() => {
          loadingInstance.close()
          val && open(val.data, "_blank")
          // getCourseByID(competition_id).then(val => {
          //   console.log("refresh this course:", competition_id, index, val)
          //   this.$set(this.courseList, index, val.data)     
          // }, response => {
          //   console.log("refresh this course:", competition_id, response.data.message)
          // })
        }, 1000)
      }, response => {
        this.redirectToLogin(response)
        loadingInstance.close()
      })
    },
    test() {
      let routeUrl = this.$router.resolve({
        path: "/competition/competition",
        // params: {billId:21}
      });
      open(routeUrl.href, '_blank');
    },
    IsOngoing(competition) {
      const timestamp = new Date().getTime()
      return Date.parse(competition.begin_time) < timestamp && Date.parse(competition.end_time) > timestamp
    }
	},
	computed: {
    futureCompetitions () {
      const timestamp = new Date().getTime()
      return this.competitionList.filter(c => Date.parse(c.begin_time) > timestamp)
    },
    pastCompetitions () {
      const timestamp = new Date().getTime()
      return this.competitionList.filter(c => Date.parse(c.end_time) < timestamp)
    }
  },
  created() {
    this.getCompetitionList()
  }
})
</script>

<style lang="scss" scoped>

</style>
