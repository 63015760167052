import {request} from './request'

export function getCompetitionList () {
  return request({
    url: '/competition',
    useJwt: true,
  })
}

export function enroll (competition_id) {
  return request({
    url: '/competition/enroll/' + competition_id,
    method: 'post',
    useJwt: true,
  })
}

export function quit (competition_id) {
  return request({
    url: '/competition/quit/' + competition_id,
    method: 'post',
    useJwt: true,
  })
}

export function participate (competition_id) {
  return request({
    url: '/competition/participate/' + competition_id,
    method: 'post',
    useJwt: true,
  })
}

export function competitionWorkspace () {
  return request({
    url: '/competition/workspace',
    method: 'post',
    useJwt: true,
  })
}
