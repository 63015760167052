import {request} from './request'

export function getCourseList () {
  return request({
    url: '/course',
    useJwt: true,
  })
}

export function getCourseByID (course_id) {
  return request({
    url: '/course/' + course_id,
    useJwt: true,
  })
}

export function forkCourse (course_id) {
  return request({
    url: '/course/fork/' + course_id,
    method: 'post',
    useJwt: true,
  })
}