import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import router from '../router'
import { Message } from 'element-ui';

export function request (config) {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL + '/api/' + (window.sessionStorage.getItem('cloud-lang') || 'zh'),
    // baseURL: 'http://192.168.11.102:20291/cloud',
    // baseURL: 'http://localhost:3000',
    // baseURL: 'http://192.168.1.101:8001',
    // baseURL: 'http://123.207.32.32:8000',
    timeout: config.timeout || 5000,
    method: config.method || 'get',
  })

  instance.interceptors.request.use(config => {
    // console.log(config)
    if (config.useJwt) {
      config.headers.Authorization = "Bearer " + store.getters.CurrentUser.token;
    }
    // config.params.lang = window.sessionStorage.getItem('lang') || 'zh';
    // console.log('@@@@', config.headers.Authorization)
    return config
  }, error => {
    // console.log('request', error)
  })
  instance.interceptors.response.use(result => {
    // console.log('request: result', result)
    if (result.status === 200) {            
      return Promise.resolve(result.data);        
    } else {            
      return Promise.reject(result);        
    }  
    // return result.data
  }, error => {
    console.log('response: error', error)
    if (error.code === 'ECONNABORTED' || error.response.status === 502) {
      error.data = {message: Vue.prototype.$bus.$t('error.backendBroken')}
      return Promise.reject(error);
    }
    if (error.response && error.response.data && error.response.data.message === "Invalid or expired JWT") {
      store.commit('user/Logout')
      Message({
        message: Vue.prototype.$bus.$t('auth.loginExpired'),
        offset: 65,
        showClose: true,
        duration: 2000,
      })
      router.push('/auth/login')
    } else {
      if (error.response.data && error.response.data.message) {
        return Promise.reject(error.response);
      }
      error.data = {message: error.response.status + " " + error.response.statusText};
      return Promise.reject(error);
    }    
  })

  // 发送真正的网络请求
  return instance(config)
}
