<template>
  <div id="about">
    <div class="container">
      <h1 class="title">{{$t('home.title')}}</h1>
      <div class="about">{{$t('about.institution')}}</div>
      <!-- <div class="about">{{$t('about.contactUs')}}{{$t('about.zhaoyongwang')}} <a href="mailto:zhaoyw@zju.edu.cn">zhaoyw@zju.edu.cn</a>&nbsp;&nbsp;{{$t('about.xuhao')}} <a href="mailto:yulu22@163.com">yulu22@163.com</a></div> -->
      <div class="about">{{$t('about.contactUs')}}<a href="mailto:zhaoyw@zju.edu.cn">{{$t('about.zhaoyongwang')}}</a>&nbsp;&nbsp;<a href="mailto:yulu22@163.com">{{$t('about.xuhao')}}</a></div>
      <!-- <div class="about">问题反馈：请将截图和操作方法发送到<a href="mailto:isabelle_info@163.com">isabelle_info@163.com</a>，我们会尝试复现和修复。</div> -->
      <div class="about">{{$t('about.feedback')}}<br/><p><img src="../../../public/QRcode.jpg" /></p></div>
    </div>
	</div>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {}
  },
}
</script>

<style scoped>
.title {
  font-size: 50px;
  margin-top: 70px;
}
.container {
  margin: auto;
  width: 50%;
}
.about {
  font-size: 20px;
  line-height: 32px;
  color: #57606a;
  margin-top: 50px;
  text-align: left;
}
a {
  color: #0969da;
}
a:hover, a:active, a:focus {
  color: #0969da;
  text-decoration: underline;
}
p {
  text-align: center !important;
}
</style>