import {request} from './request'
import {encrypt} from '../utils/encrypt'

export function login (identity, password) {
  return request({
    url: '/auth/login',
    // useJwt: true,
    method: 'post',
    data: {
      identity,
      password: encrypt(password)
    }
  })
}

export function register (user_name, email, password) {
  return request({
    url: '/auth/register',
    // timeout: 2000,
    // useJwt: true,
    method: 'post',
    data: {
      user_name,
      email,
      password: encrypt(password)
    }
  })
}

export function registerCheckUsernameExist (user_name) {
  return request({
    url: '/auth/register-check',
    // useJwt: true,
    method: 'get',
    params: {
      type: 'user_name',
      user_name,
    }
  })
}

export function registerCheckEmailExist (email) {
  return request({
    url: '/auth/register-check',
    // useJwt: true,
    method: 'get',
    params: {
      type: 'email',
      email,
    }
  })
}

export function activation (token) {
  return request({
    url: '/auth/activate/' + token,
    // useJwt: true,
    method: 'get',
  })
}

export function findPasswordVerification (email) {
  return request({
    url: '/auth/findpasswordverification',
    useJwt: true,
    method: 'get',
    params: {
      // type: 'email',
      email,
    }
  })
}

export function findPassword (email, verification, oldPassword, newPassword) {
  return request({
    url: '/auth/findpassword',
    useJwt: true,
    method: 'post',
    data: {
      email,
      verification,
      old_password: oldPassword && encrypt(oldPassword),
      new_password: encrypt(newPassword),
    }
  })
}
