<template>
  <div>
    <div class="beian"><a href="https://beian.miit.gov.cn/" target="_blank"> 粤ICP备2022150953号 </a></div>
  </div>
</template>

<script>

export default ({
  name: 'Foot',
})
</script>

<style scoped>
  .beian {
    margin: 40px 0 10px 0;
  }
</style>