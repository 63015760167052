import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueClipboard from 'vue-clipboard2'
import i18n from './lang'
import Debounce from '@/utils/debounce'
import '@/styles/index.scss'
import '@/icons' // icon
Vue.config.productionTip = false

// import VueRouter from "vue-router"; 
// Vue.use(VueRouter)
Vue.use(ElementUI);
Vue.use(VueClipboard);
Vue.component('Debounce', Debounce);

new Vue({
  render: h => h(App),
  // template: `<App></App>`,
  // components: {App}
  store,
  router,
  i18n,
  beforeCreate() {
    Vue.prototype.$bus = this
  }
}).$mount('#app')
