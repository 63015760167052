<template>
  <div class="courses">
    <!-- <div class="course" v-for="(course,i) in courseList" :key="i">
      <div class="inner-course" @click="openLink(course.link)" >
        <div class="course-snap"><p><img :src="require('../../assets/img/courses/'+course.cover)" alt="" width="270px"></p></div>
        <div class="course-text">
          <h2>{{course.course_name}}</h2>
          <p>{{course.description}}</p>
          <div class="course-author">
            {{course.creator}}
          </div>
          <div class="dropdown" v-show="course.can_fork">
            <el-button class="button" @click.prevent.stop="forkCourse(course.id, i)">
              <i class="el-icon-more"></i>
              {{forkButtonDisable(course) ? 'open': 'fork'}}
            </el-button>
          </div>
        </div>
      </div>
    </div> -->

    <el-card class="course" v-for="(course,i) in courseList" :key="i" shadow="hover" :body-style="{ padding: '6px' }">
      <div class="course-snap"><p><img :src="require('../../assets/img/courses/'+course.cover)" alt="" width="270px"></p></div>
      <div class="course-text">
        <h2>{{course.course_name.startsWith('i18n-') ? $t('course.'+course.course_name): course.course_name}}</h2>
        <p>{{course.description.startsWith('i18n-') ? $t('course.'+course.description): course.description}}</p>
      </div>
      <div class="course-bottom">
        <div class="course-author">{{course.creator.startsWith('i18n-') ? $t('course.'+course.creator): course.creator}}</div>
        <div class="dropdown">
          <!-- <el-button class="button" @click.prevent.stop="forkCourse(course.id, i)">
            <i class="el-icon-more"></i>
            {{forkButtonDisable(course) ? 'open': 'fork'}}
          </el-button> -->
          <el-dropdown trigger="click" size="small" @command="handleCourseClick">
            <el-button icon="el-icon-arrow-down"></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{command:openLink,params:[course.link]}">Open Course</el-dropdown-item>
              <el-dropdown-item v-if="course.can_fork" :command="{command:forkCourse,params:[course.id,i]}">
                {{forkButtonDisable(course) ? 'Open Code': 'Fork Code'}}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
// import StudyContent from "./StudyContent.vue";
import { getCourseList, getCourseByID, forkCourse } from '../../network/course'
import { Loading } from 'element-ui'

export default {
  name: 'Course',
  data() {
    return {
      courseList: [],
    }
  },
  methods: {
    getCourseList() {
      getCourseList().then(val => {
        // console.log("val", val)
        this.courseList = val.data || []
        // const {href} = this.$router.resolve({path: '/study-content', query:{id:1}})
        // open(href, "_blank")
      }, response => {
        this.$message({
          message: response.data.message,
          offset: 65,
          showClose: true,
          duration: 2000,
        });
      })
    },
    handleCourseClick(command) {
      command.command && command.command(...command.params)
    },
    openLink(link) {
      open(link, '_blank')
    },
    forkButtonDisable (course) {
      return course.workspace_id && course.workspace_id > 0
    },
    forkCourse(course_id, index) {
      let loadingInstance = Loading.service({
        fullscreen: true
      })
      forkCourse(course_id).then(val => {
        setTimeout(() => {
          loadingInstance.close()
          val && open(val.data, "_blank")
          // console.log('0', this.courseList)
          getCourseByID(course_id).then(val => {
            // console.log("refresh this course:", course_id, index, val)
            // console.log('1', this.courseList)
            // this.courseList[index] = val.data
            // console.log('2', this.courseList)
            this.$set(this.courseList, index, val.data)     
          }, response => {
            // console.log("refresh this course:", course_id, response.data.message)
          })
        }, 2000)
      }, response => {
        // console.log(response)
        if (response.status == 401) {
          this.$router.push({
            path: '/auth/login',
            query: {
              redirect: this.$router.currentRoute.fullPath
            }
          })
          this.$message({
            message: this.$t('auth.pleaseLogin'),
            offset: 65,
            showClose: true,
            duration: 2000,
          });
        } else{
          this.$message({
            message: response.data.message,
            offset: 65,
            showClose: true,
            duration: 2000,
          });
        }
        loadingInstance.close()
      })
    },
  },
  computed: {
  },
  created() {
    this.getCourseList()
  }
  // components: { StudyContent }
}
</script>


<style scoped>
.courses {
  /* display: grid; */
  /* grid-template-columns: repeat(3, 1fr); */
  /* column-gap: 2rem; */
  margin: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}
.course {
  margin: 2rem;
  box-sizing: border-box;
  width: 350px;
  height: 520px;
  /* display: inline-block; */
}
.inner-course {
  transition: all 0.3s ease 0s;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  position: relative;
  flex: 1 1 0%;
  flex-grow: 1;
  min-width: 300px;
  -moz-box-flex: 1;
  width: 100%;
  /* height: 100%; */
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  background-color: rgb(250, 250, 250);
  /* border: 1px solid rgb(36, 36, 36); */
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4px;
  -moz-box-flex: 0;
  flex-grow: 0;
  min-width: calc(33% - 1rem);
}
.inner-course:hover {
  color: black;
  box-shadow: rgb(4, 4, 4) 0px 4px 16px 0px;
}
.course-snap {
  display: block;
  margin-top: 10px;
  margin-bottom: 0px;
  z-index: 0;
  /* border-bottom: 3.2px solid rgb(97, 218, 251); */
  height: 353px;
  width: 100%;
  /* background-color: rgb(36, 36, 36); */
  border-image-width: 0;
}
.course-snap > p {
  height: 353px;
  line-height: 353px;
}
.course-snap > p > img {
  vertical-align: middle;
}
.course-text {
  left: -1px;
  right: -1px;
  padding: 0.75rem;
  min-height: 90px;
  z-index: 1;
  height: 130px;
  text-align: left;
}
.course-text > h2 {
  /* color: rgb(97, 218, 251); */
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 6px;
  margin-top: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: 20px;
  line-height: 1.2;
  padding: 0;
  /* margin: 0 0 1.0875rem; */
  /* margin-top: 0px; */
  /* margin-bottom: 0px; */
  color: rgb(100, 100, 100);
  font-weight: 700;
  text-rendering: optimizeLegibility;
}
/* .course-text > h2:hover {
  color: black;
} */
p {
  font-size: 0.8rem;
  color: rgb(90, 90, 90);
  font-weight: 500;
  /* line-height: 1.3; */
  /* margin-top: 8px; */
  /* overflow: hidden; */
  /* text-overflow: clip/ellipsis; */
  /* display: -webkit-box; */
  /* -moz-box-orient: vertical; */
  /* -webkit-line-clamp: 2; */
  /* max-height: 35px; */
  letter-spacing: 0;
  text-rendering: optimizeLegibility;
  padding: 0;
  /* margin: 0 0 1.0875rem; */
  /* height: 58px; */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  /* overflow:hidden; 
  text-overflow:ellipsis; */
  /* white-space: nowrap; */
}
.course-author {
  display: inline-block;
  /* -moz-box-pack: center; */
  /* justify-content: center; */
  /* -moz-box-align: center; */
  /* align-items: center; */
  /* flex-direction: row; */
  font-size: 0.75rem;
  font-weight: 600;
  position: relative;
  bottom: 1rem;
  /* left: 0.75rem; */
  text-decoration: none;
  margin-top: 10px;
  color: rgb(140, 140, 136);
}
.course-bottom {
  text-align: left;
  margin-left: 10px;
}
.dropdown {
  /* display: inline-block; */
  position: relative;
  bottom: 0.75rem;
  /* right: 0.5rem; */
  /* cursor: pointer; */
  float: right;
}
button {
  cursor: pointer;
  padding: 3px 5px;
}
/* .grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
} */
</style>