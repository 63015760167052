import {request} from './request'

export function getWorkspacesByUserId () {
  return request({
    url: '/workspace',
    useJwt: true,
  })
}

export function createWorkspace (template_id, workspace_name, description) {
  return request({
    url: '/workspace/create',
    useJwt: true,
    method: 'post',
    data: {
      template_id,
      workspace_name,
      description
    }
  })
}

export function openWorkspace (workspace_id) {
  return request({
    url: '/workspace/open/' + workspace_id,
    useJwt: true,
    method: 'post',
  })
}

export function getAllTemplates () {
  return request({
    url: '/workspace/templates',
    useJwt: false,
  })
}

export function deleteWorkspace (workspace_id) {
  return request({
    url: '/workspace/delete/' + workspace_id,
    useJwt: true,
    method: 'post',
  })
}