export default {
	general: {
		submit: 'Submit',
	},
	topbar: {
		title: 'Cloud IDE for Isabelle/HOL Theorem Prover',
		home: 'Home',
		dashboard: 'Dashboard',
		library: 'Libraries',
		course: 'Courses',
		about: 'About',
		language: 'Language',
		logout: 'Log Out',
		login: 'Login',
		register: 'Register',
		changePassword: 'Change Password',
	},
	auth: {
		toLogin: 'To Login Page',
		toRegister: 'To Register Page',
		name: 'Name',
		mail: 'Mail',
		password: 'Password',
		verification: 'Verfication',
		newPassword: 'New Password',
		oldPassword: 'Old Password',
		getVerification: 'Get Verfication',
		seconds: 's',
		findpassword: 'Find Password',
		pleaseLogin: 'Please log in first',
		loginExpired: 'Login expired, please login first',
		pleaseInputPassword: 'Please enter your password',
		activateSuccess: 'Activation successful, please log in again',
		activateFailLogin: 'Unable to activate account while logged in',
		mailAwaitActivation: 'An email has been sent to your inbox, awaiting activation',
		verificationMailSent: 'A verification email has been sent to your inbox',
		usernameExists: 'The username already exists',
		pleaseInputMail: 'Please enter your email',
		pleaseInputValidMail: 'Please enter a valid email',
		mailExists: 'The email has already been used',
		pleaseInputVerificationCode: 'Please enter the verification code',
		emailNoAccount: 'No account exists for the provided email',
		pleaseInputOldPassword: 'Please enter your old password',
		pleaseInputNewPassword: 'Please enter your new password',
		passwordTheSame: 'The new password is the same as the old password',
	},
	home: {
		title: 'Cloud IDE for Isabelle/HOL Theorem Prover',
		description: 'Isabelle/Cloud is the cloud-based version of the Isabelle theorem prover, which offers users a web-based interactive interface based on VS Code. It containerizes Isabelle, allowing for better utilization of its powerful capabilities.',
		start: 'Start',
		featureTitle1: 'Theorem Proving as a Service (TPaaS)',
		featureDesc1: 'The web frontend utilizes VS Code, eliminating the need for installing and maintaining a desktop IDE. The backend, Isabelle Server, is containerized using Docker, providing on-demand allocation of computational and storage resources.',
		featureTitle2: 'Deep Integration of Isabelle',
		featureDesc2: 'The VS Code frontend is deeply integrated with Isabelle, providing syntax highlighting, real-time error detection for functional languages and proof scripts, and instant visualization of the theorem proving process.',
		featureTitle3: 'Multiple Versions on a Single Platform',
		featureDesc3: 'It supports the latest versions of Isabelle, such as Isabelle 2019/2020/2021, allowing users to choose the desired version when creating a project.',
		featureTitle4: 'Powerful Project Management',
		featureDesc4: 'Users can create multiple Isabelle projects, utilizing a Docker file system for isolation and management. Project resources and states are maintained and managed in the cloud, supporting hot reloading of projects.',
		featureTitle5: 'Pre-integration and On-Demand Loading of Third-Party Libraries',
		featureDesc5: 'The platform comes pre-integrated with numerous third-party libraries, allowing users to selectively import and use them as needed during development.',
		featureTitle6: 'Rich Teaching Resources with IDE Integration',
		featureDesc6: 'The platform gathers and organizes a wide range of instructional materials and case studies from both domestic and international sources. Users can directly create Isabelle projects from tutorial code, enabling hands-on practice and exercises. The integration with the IDE enhances the learning experience.',
	},
	workspace: {
		create: 'New workspace',
		pleaseInputName: 'Please enter your name',
		pleaseInputNameLength: 'Up to 20 characters in length',
		pleaseSelectTemplate: 'Please select a template',
		pleaseInputDesc: 'Please enter a description',
		pleaseInputDescLength: 'Up to 100 characters in length',
	},
	library: {
		description: ' is located in the /workspace directory of the workspace. You can reference theories within it based on their session names. For example, you can import a theory with the session name "Abs_Int_ITP2012.Abs_Int3" using the command',
	},
	course: {
		'i18n-FunctionalProgramDesignAndProofTitle': 'Functional Programming and Proof',
		'i18n-FunctionalProgramDesignAndProofDesc': 'This book primarily focuses on the topics of functional program design and proof, emphasizing a practical approach. It utilizes the Isabelle/HOL tool as an environment for program development and proof.',
		'i18n-FunctionalProgramDesignAndProofAuthor': 'Yongwang Zhao from Zhejiang University',
	},
	about: {
		institution: 'Research Institutions: College of Computer Science and Technology, Zhejiang University; School of Cyber Science and Technology, Zhejiang University the National Key Laboratory of Blockchain and Data Security',
		contactUs: 'Contact us: ',
		zhaoyongwang: 'Yongwang Zhao',
		xuhao: 'Hao Xu',
		feedback: 'Question Feedback: Scan to follow the WeChat official account and add the WeChat group to feedback questions.',
	},
	error: {
		backendBroken: 'Server is busy, please try again later',
		repeatedSubmit: 'Please do not submit repeatedly',
	},
}
