export default {
	userManager: {
		addBtn: '添加',
		searchPlacholder: '请输入账号、姓名、工号',
		searchBtn: '查询',
		account: '账号',
		username: '姓名',
		departmentFullPath: '部门全称',
		positionName: '岗位名称',
		status: '状态',
		operate: '操作'
	},
	test: '测试',
	general: {
		submit: '提交',
	},
	topbar: {
		title: 'Isabelle定理证明云平台',
		home: '首页',
		dashboard: '工作台',
		library: '库',
		course: '教学',
		about: '关于',
		language: '语言',
		logout: '退出登录',
		login: '登录',
		register: '注册',
		changePassword: '修改密码',
	},
	auth: {
		toLogin: '切换到登录页面',
		toRegister: '切换到注册页面',
		name: '昵称',
		mail: '邮箱',
		password: '密码',
		verification: '验证码',
		newPassword: '新密码',
		oldPassword: '旧密码',
		getVerification: '获取验证码',
		seconds: '秒',
		findpassword: '找回密码',
		pleaseLogin: '请先登录',
		loginExpired: '登录过期，请先登录',
		pleaseInputPassword: '请输入密码',
		activateSuccess: '激活成功，请重新登录',
		activateFailLogin: '处于登录状态不能激活账号',
		mailAwaitActivation: '一封邮件已经发送到您的邮箱，等待激活',
		verificationMailSent: '验证邮件已发送到邮箱',
		usernameExists: '用户名已存在',
		pleaseInputMail: '请输入邮箱',
		pleaseInputValidMail: '请输入合法的邮箱',
		mailExists: '邮箱已被使用',
		pleaseInputVerificationCode: '请输入验证码',
		emailNoAccount: '邮箱对应的账号不存在',
		pleaseInputOldPassword: '请输入旧密码',
		pleaseInputNewPassword: '请输入新密码',
		passwordTheSame: '新密码与旧密码相同',
	},
	home: {
		title: 'Isabelle定理证明云平台',
		description: 'Isabelle/Cloud是Isabelle定理证明器的云化版本，为用户提供基于VS Code的Web交互界面，将Isabelle容器化，更好地发挥Isabelle的强大作用。',
		start: '开始',
		featureTitle1: '定理证明即服务(TPaaS)',
		featureDesc1: '采用VS Code的Web前端，免去桌面IDE的安装维护；后端Isabelle Server采用Docker容器化，运算存储能力按需配额',
		featureTitle2: 'Isabelle深度集成',
		featureDesc2: 'VS Code前端与Isabelle深度集成，高亮语法显示，函数式语言与证明脚本的语法错误即时提示，定理证明过程即时展现',
		featureTitle3: '一个平台多个版本',
		featureDesc3: '支持Isabelle 2019/2020/2021等最新版本，创建项目时按需选择',
		featureTitle4: '强大的项目管理',
		featureDesc4: '用户可以创建多个Isabelle项目，采用Docker文件系统进行隔离与管理；项目资源与状态在云端管理维护，支持项目的热加载',
		featureTitle5: '第三方库的预先集成与按需加载',
		featureDesc5: '平台预先集成了众多的第三方库，用户可以在开发中按需导入使用',
		featureTitle6: '教学资源丰富，并与IDE集成',
		featureDesc6: '收集整理国内外相关教程和案例，可直接从教程代码创建Isabelle项目，进行实战演练',
	},
	workspace: {
		create: '创建工作空间',
		pleaseInputName: '请输入名称',
		pleaseInputNameLength: '长度在20个字符以内',
		pleaseSelectTemplate: '请选择模板',
		pleaseInputDesc: '请输入描述',
		pleaseInputDescLength: '长度在100个字符以内',
	},
	library: {
		description: '放置于工作空间的/workspace目录下，可以根据session名引用其中的theory，例如',
	},
	course: {
		'i18n-FunctionalProgramDesignAndProofTitle': '函数式程序设计与证明',
		'i18n-FunctionalProgramDesignAndProofDesc': '本书主要介绍函数式程序设计与证明的相关内容，重点从实践的角度进行介绍，整体使用Isabelle/HOL工具，作为程序开发及证明的工具环境。每章的程序和证明过程，本书均提供了配套的源代码，可供下载。',
		'i18n-FunctionalProgramDesignAndProofAuthor': '赵永望@浙江大学',
	},
	about: {
		institution: '研发机构：浙江大学 计算机科学与技术学院、网络空间安全学院，区块链与数据安全全国重点实验室',
		contactUs: '联系我们：',
		zhaoyongwang: '赵永望',
		xuhao: '许浩',
		feedback: '问题反馈：扫描关注微信公众号，加微信群反馈问题。',
	},
	error: {
		backendBroken: '后台繁忙，请稍后再试',
		repeatedSubmit: '请勿重复提交',
	},
}
