<template>
  <div class="dashborad-home">
    <div class="containers">
      <div class="container">
        <button @click="$bus.$emit('ShowAddWorkspace')" class="add-workspace">
          <div class="plus">
            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="sc-bdnylx eoHNwX"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.467 2.66667H8.53366V7.46666H13.3337V8.53333H8.53366V13.3333H7.467V8.53333H2.66699V7.46666H7.467V2.66667Z" fill="currentColor"></path></svg>
            <span class="plus-span">New Workspace</span>
          </div>
        </button>
      </div>
      <!-- <div class="container">
        <button class="inner-container" @click.right.prevent.stop="handleClick($event, 1)" ref="abc">
          <div class="container-snap"><img src="../../../public/43.png" alt="" width="270px"></div>
          <div class="container-name">test</div>
          <div class="container-desc">test2</div>
          <div class="close">
            <circle class="circle" cx="26" cy="26" r="25" fill="none" />
            <path class="line" fill="none" d="M17.36,34.736l17.368-17.472" />
            <path class="line" fill="none" d="M34.78,34.684L17.309,17.316" />
          </div>
        </button>
      </div> -->
      <el-card class="container" v-for="(item, index) in GetCommonWorkspaces" :key="index" shadow="hover" :body-style="{ padding: '5px', width: '90%' }">
        <!-- <button class="inner-container" @click="openWorkspace(item)" @click.right.prevent.stop="handleClick($event, item)"> -->
          <div class="container-snap">
            <img :src="require('../../assets/img/isabelle.svg')" alt="" height="120px">
            <span class="version">{{item.version}}</span>
          </div>
          <div class="container-text">
            <div class="container-name">{{item.workspace_name}}</div>
            <div class="container-desc">{{item.description}}</div>
          </div>
        <!-- </button> -->
        <div class="dropdown">
          <el-dropdown trigger="click" size="small" @command="handleWorkspaceClick">
            <el-button class="dropdown-button" icon="el-icon-arrow-down"></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{command:openWorkspace,item}">Open Workspace</el-dropdown-item>
              <!-- <el-dropdown-item :command="{command:copyDescription,item}">Copy Name &amp; Description</el-dropdown-item> -->
              <el-dropdown-item :command="{command:deleteWorkspace,item}">Delete Workspace</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-card>

      <el-divider v-if="GetCourseWorkspaces.length > 0"></el-divider>
      <el-card class="container" v-for="(item, index) in GetCourseWorkspaces" :key="'course'+index" shadow="hover" :body-style="{ padding: '5px', width: '90%' }">
        <div class="container-snap">
          <img :src="require('../../assets/img/courses/'+item.cover)" alt="" height="120px">
        </div>
        <div class="version">{{item.version}}</div>
        <div class="container-text">
          <!-- <div class="container-name">{{item.workspace_name}}</div> -->
          <div class="container-name">{{item.workspace_name.startsWith('i18n-') ? $t('course.'+item.workspace_name): item.workspace_name}}</div>
          <!-- <div class="container-desc">{{item.description}}</div> -->
          <div class="container-desc">{{item.description.startsWith('i18n-') ? $t('course.'+item.description): item.description}}</div>
        </div>
        <div class="dropdown">
          <el-dropdown trigger="click" size="small" @command="handleWorkspaceClick">
            <el-button class="dropdown-button" icon="el-icon-arrow-down"></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{command:openWorkspace,item}">Open Workspace</el-dropdown-item>
              <!-- <el-dropdown-item :command="{command:copyDescription,item}">Copy Name &amp; Description</el-dropdown-item> -->
              <el-dropdown-item :command="{command:deleteWorkspace,item}">Delete Workspace</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-card>
    </div>
    
    <AddWorkspace/>
    <!-- <VueSimpleContextMenu
      element-id="context-menu"
      :options="contextMenuButtons"
      ref="vueSimpleContextMenu"
      @option-clicked="optionClicked"
    /> -->
  </div>
</template>

<script>
import { getWorkspacesByUserId, createWorkspace, openWorkspace, deleteWorkspace } from '../../network/dashborad'
import AddWorkspace from '../../components/AddWorkspace'
import { Loading } from 'element-ui'
// import VueSimpleContextMenu from 'vue-simple-context-menu'
import '../../assets/css/vue-simple-context-menu.css';

export default {
  name: 'DashboardHome',
  components: {
    // VueSimpleContextMenu,
    AddWorkspace
  },
  data() {
    return {
      // isAddWorkspaceShow: false,
      workspaces: [
        // {workspace_name:'我的工作空间', description:'这是一个工作空间，名字要长长长长长长长长长长长长长长长长长长长长长长长长长长长长长长长长。'},
        // {workspace_name:'我的工作空间', description:'这是一个工作空间，名字要长长长长长长长长长长长长长长长长长。'},
      ],
      // contextMenuButtons: [
      //   {'name': '打开', callback: this.openWorkspace},
      //   {'name': '复制名称和描述', callback: this.copyDescription},
      //   {'name': '删除', callback: this.deleteWorkspace},
      // ],
      curRightClickWorkspace: null,
    }
  },
  methods: {
    getWorkspacesByUserId() {
      getWorkspacesByUserId().then(val => {
        if (val) {
          this.workspaces = val.data || []
          // this.workspaces.forEach(
          //   item => {
          //     // console.log(item)
          //     item.cover = item.cover === '' ? '../../assets/img/isabelle.svg' : '../../assets/img/courses/' + item.cover
          //   }
          // )
        }
      }, response => {
        this.$message({
          offset: 65,
          showClose: true,
          duration: 2000,
          message: response.data.message})
      })
    },
    handleWorkspaceClick(command) {
      command.command && command.command(command.item)
    },
    createWorkspace(createInfo) {
      let loadingInstance = Loading.service({
        fullscreen: true
      })
      createWorkspace(createInfo.id, createInfo.title, createInfo.description).then(val => {
        setTimeout(() => {
          loadingInstance.close()
          open(val.data, "_blank")
          this.getWorkspacesByUserId()
        }, 2000)
      }, response => {
        this.$message({
          message: response.data.message,
          offset: 65,
          showClose: true,
          duration: 2000,
        });        
        loadingInstance.close()
      })
    },
    openWorkspace(workspace) {
      let loadingInstance = Loading.service({
        fullscreen: true
      })
      openWorkspace(workspace.id).then(val => {
        // loadingInstance.close()
        // val && open(workspace.url, "_blank")
        setTimeout(() => {
          loadingInstance.close()
          val && open(workspace.url, "_blank")
        }, 2000)
      }, response => {
        this.$message({
          message: response.data.message,
          offset: 65,
          showClose: true,
          duration: 2000,
        });        
        loadingInstance.close()
      })
    },
    deleteWorkspace(workspace) {
      let loadingInstance = Loading.service({
        fullscreen: true
      })
      deleteWorkspace(workspace.id).then(val => {
        loadingInstance.close()
        this.getWorkspacesByUserId()
      }, response => {
        this.$message({
          message: response.data.message,
          offset: 65,
          showClose: true,
          duration: 2000,
        });        
        loadingInstance.close()
      })
      // this.$confirm('此操作将永久删除该工作空间, 是否继续?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'4
      // }).then(() => {
      //   this.$message({
      //     type: 'success',
      //     message: '删除成功!'
      //   });
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消删除'
      //   });          
      // });
    },
    copyDescription(workspace) {
      this.$copyText(`${workspace.workspace_name}\n${workspace.description}`)
        .then(e => {
          console.log("copy text success:", e.text)
        }, e => {
          console.log("copy text failure", e)
        })
    },
    handleClick (event, workspace) {
      this.curRightClickWorkspace = workspace
      this.$refs.vueSimpleContextMenu.showMenu(event)
    },
    optionClicked (event) {
      // window.alert(JSON.stringify(event))
      this.curRightClickWorkspace && event.option.callback(this.curRightClickWorkspace)
    },
    getWorkspaceCover(workspace) {
      // return workspace.cover === '' ? '../../assets/img/isabelle.svg' : '../../assets/img/courses/' + workspace.cover
      return workspace.cover === '' ? '../../assets/img/isabelle.svg' : '../../assets/img/courses/1.png'
    }
  },
  computed: {
    GetCommonWorkspaces() {
      return this.workspaces.filter(w => !w.from_course)
    },
    GetCourseWorkspaces() {
      return this.workspaces.filter(w => w.from_course)
    },
  },
  created() {
    this.getWorkspacesByUserId()
  },
  mounted() {
    // 只要页面没有强制刷新，存在组件切换，bus.$on方法会多次绑定，造成事件多次触发。
    this.$bus.$off('CreateWorkspace')
    this.$bus.$on('CreateWorkspace', createInfo => {
      this.createWorkspace(createInfo)
    })
  }
}
</script>

<style scoped>
.containers {
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: row wrap;
}
.container {
  position: relative;
  /* left: 36px; */
  /* top: 64px; */
  /* display: inline-block; */
  height: 280px;
  width: 290px;
  margin: 20px 20px;
  
  /* margin-top: 32px; */
  /* margin-bottom: 32px; */
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.add-workspace {
  height: 100%;
  width: 100%;
  font-size: 13px;
  background-color: rgb(21, 21, 21);
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4px;
  transition: all 100ms ease-in 0s;
  box-sizing: border-box;
  padding: 0px 8px;
  background-color: transparent;
  color: rgb(100, 100, 100);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  overflow: visible;
  margin: 0;
}
.add-workspace:hover {
  color: black;
  /* box-shadow: rgb(4, 4, 4) 0px 4px 2px 0px; */
  box-shadow: rgb(0, 0, 0, .1) 0px 2px 12px 0px;
}
.plus {
  display: flex;
  flex-direction: column;
  -moz-box-align: center;
  align-items: center;
  box-sizing: border-box;
}
.plus-span {
  margin-top: 10px;
}
.version {
  /* float: right; */
  font-size: 20px;
  font-weight: 500;
  color: black;
  font-family: "Poppins", sans-serif;
  /* font-family: Inter, sans-serif; */
  position: absolute;
  top: 0em;
  right: 2em;
  /* text-align: right; */
}
.inner-container {
  outline: currentcolor none medium;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(9, 113, 241);
  border-radius: 4px;
  overflow: hidden;
  transition: box-shadow 200ms ease-in-out 0s;
  opacity: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
}
.container-snap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 135px;
  /* width: 108%; */
  /* background-color: rgb(36, 36, 36); */
  background-color: rgb(255, 255, 255);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-color: rgb(36, 36, 36);
  /* border-bottom-color: rgb(36, 36, 36); */
  /* border-bottom: 1px solid rgb(36, 36, 36); */
  padding: 25px 0;
  position: relative;
  top: 1em;
}
.container-text {
  position: relative;
  /* left: -1px; */
  /* right: -1px; */
  /* padding: 0.75rem 0; */
  min-height: 90px;
  /* z-index: 1; */
  height: 130px;
  text-align: left;
}
.container-name {
  /* margin-top: 15px; */
  /*margin-left: 10px;
  font-size: 20px;
  text-align: left;
  font-weight: 800;
  line-height: normal;
  display: inline;
  overflow: hidden;
  width: 11em;
  text-overflow: ellipsis;
  white-space: nowrap; */


  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 6px;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-height: 20px;
  line-height: 1.2;
  padding: 0;
  /* margin: 0 0 1.0875rem; */
  /* margin-top: 0px; */
  /* margin-bottom: 0px; */
  color: rgb(100, 100, 100);
  font-weight: 700;
  text-rendering: optimizeLegibility;


  letter-spacing: 0;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.container-desc {
  /* flex-grow: 1; */
  /* margin-top: -4px; */
  /* margin-left: 4px; */
  /* margin-right: 4px; */
  /* font-size: 13px; */
  /* height: 28px; */
  /* overflow: hidden; */
  /* display: -webkit-box; */
  /* -webkit-line-clamp: 2; */
  /* -webkit-box-orient: vertical; */
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  /* padding-bottom: 16px; */
  font-size: 0.8rem;
  color: rgb(90, 90, 90);
  font-weight: 500;
  line-height: 1rem;
  /* line-height: 1.3; */
  /* margin-top: 2px; */
  /* overflow: hidden; */
  /* text-overflow: clip/ellipsis; */
  /* display: -webkit-box; */
  /* -moz-box-orient: vertical; */
  /* -webkit-line-clamp: 2; */
  /* max-height: 35px; */
  letter-spacing: 0;
  text-rendering: optimizeLegibility;
  padding: 0;
  /* margin: 0 0 1.0875rem; */

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.dropdown {
  position: relative;
  bottom: 1.5rem;
  float: right;
  /* height: 20px; */
}
.dropdown-button {
  padding: 6px 8px;
}
</style>