<template>
  <div id="competition">
    <!-- <iframe src="https://localhost:9025/"></iframe> -->

    <div class="box" ref="box">
      <div class="left" ref="left" :style="{width:leftWidth}">
        <markdown-it-vue class="md-body" :content="markdown" />
      </div>
      <div class="resize" ref="resize" :style="{backgroundColor: resizeBackgroundColor, cursor: resizeCursor}"
        @mousedown.stop.prevent="onResizeMouseDown"
        @mouseup.stop.prevent="onResizeMouseUp"
      >
        <div class="resize-button">⋮</div>
      </div>
      <div class="right" ref="right" :style="{width:rightWidth, height: '100%'}">
        <div class="iframeDiv"></div>
        <iframe
          id="iFrame1"
          name="iFrame1"
          width="100%"
          height="100%"
          frameborder="0"
          scrolling="auto"
          :src="iframeUrl"
        >
        </iframe>
        <!-- <object :src="iframeUrl" width="100%" height="100%" type="text/html"> -->
          <!-- Alternative Content -->
      <!-- </object> -->
      </div>
    </div>
  </div>
</template>

<script>
import {competitionWorkspace,} from "../../network/competition";
import { Loading } from "element-ui";
import MarkdownItVue from 'markdown-it-vue'
import 'markdown-it-vue/dist/markdown-it-vue.css'

export default {
  name: "Competition",
  data() {
    return {
      iframeUrl: "http://localhost:9026",
      // iframeUrl: "https://element.eleme.cn/#/zh-CN/component/drawer",
      leftWidth: '32%',
      resizeBackgroundColor: '#d6d6d6',
      resizeLeft: '100px',
      resizeCursor: 'col-resize',
      markdown: `# Hello World

## **Structure of Problem Statements**

This problem should illustrate how to use the system.

Below you find three theories:

**Defs**
    containing all the definitions that are needed fo this problem

**Check**
    containing the goal of the problem. Most of the time this will be one ore more theorems.

**Submission**
    being a template for you in order to solve the problem. Most of the time this will consist of the theorems that are to be shown with sorry as justification. Maybe some hints are given by providing a proof outline. 

## **How to come up with a solution**

 In order to find a solution we recommend to make a new directory with three fresh theories Defs.thy, Submission.thy and Check.thy. Start Isabelle/JEdit and copy the content of the textboxes below into the appropriate files. If you are new to Isabelle, find a short installation guide at the end of this text

Now edit your Submission file and prove the lemma 'goal', e.g. "by simp". In order to check whether your solution is correct it suffices to let Isabelle proof-check the Check theory. **Keep in mind that you must not alter the Defs and Checks files. If you do, the proof checking in our system may give a different result from your local copy**

## **How to submit your solution**

Once you solved the problem, and it is checked successfully on your machine, you can navigate to the appropriate submit form and upload the Submission file. Note that, the Defs and Check file need not be uploaded.

Now you can review the status of your Submission by using the "My Submissions" page. There you find all your submissions (to the current contest) and their status: here, "Pending" means, that your submission is in the grading pipeline, "Failed" means that some error occured (you can view details when hovering over the "Failed" textbox) and "Passed" means that you solved the problem - congratulations.

The Leaderboard shows your score in the current contest and how you perform relative to the other contestants.

## **How to setup the Proof Assistant Isabelle**

Setting up Isabelle takes only a few steps: After downloading it from the [webpage](https://isabelle.in.tum.de/), you just need to unpack it and you are ready to go. The first startup may take a while because the Editor Isabelle/JEdit will build Isabelle/HOL's Library which you can then use.

# Resources

## **Definitions File**

\`\`\`
theory Defs imports Main
begin

definition hello :: "bool => bool" where [simp]:
  "hello x == x"
definition [simp]: "world == True"          

end
\`\`\`

## **Template File**
\`\`\`Isabelle
theory Submission 
imports Defs 
begin 

lemma goal: "hello world" sorry    

end
\`\`\`

## **Check File**
\`\`\`
theory Check imports Submission begin 

lemma  "hello world" by(rule Submission.goal)

end
\`\`\``,
      // resizeStyle: {width: '32%', backgroundColor: '#d6d6d6'}
    };
  },
  components: {
    MarkdownItVue
  },
  methods: {
    dragControllerDiv() {
      var resize = document.getElementsByClassName("resize");
      var left = document.getElementsByClassName("left");
      var mid = document.getElementsByClassName("right");
      var box = document.getElementsByClassName("box");
      for (let i = 0; i < resize.length; i++) {
        // 鼠标按下事件
        resize[i].onmousedown = function (e) {
          //颜色改变提醒
          // resize[i].style.background = "#818181";
          var startX = e.clientX;
          resize[i].left = resize[i].offsetLeft;
          // 鼠标拖动事件
          document.onmousemove = function (e) {
            var endX = e.clientX;
            var moveLen = resize[i].left + (endX - startX); // （endx-startx）=移动的距离。resize[i].left+移动的距离=左边区域最后的宽度
            var maxT = box[i].clientWidth - resize[i].offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度

            if (moveLen < 32) moveLen = 32; // 左边区域的最小宽度为32px
            if (moveLen > maxT - 150) moveLen = maxT - 150; //右边区域最小宽度为150px

            resize[i].style.left = moveLen; // 设置左侧区域的宽度

            for (let j = 0; j < left.length; j++) {
              left[j].style.width = moveLen + "px";
              mid[j].style.width = box[i].clientWidth - moveLen - 10 + "px";
            }
          };
          // 鼠标松开事件
          // document.onmouseup = function (evt) {
          //   //颜色恢复
          //   // resize[i].style.background = "#d6d6d6";
          //   document.onmousemove = null;
          //   document.onmouseup = null;
          //   resize[i].releaseCapture && resize[i].releaseCapture(); //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
          // };iframeDiv
          resize[i].setCapture && resize[i].setCapture(); //该函数在属于当前线程的指定窗口里设置鼠标捕获
          return false;
        };
      }
    },
    onResizeClicked (e) {
      this.resizeBackgroundColor = '#818181'
      // let startX = e.clientX;
      // this.resizeLeft = this.$refs.resize.offsetLeft;
    },
    onResizeMouseDown (e) {
      console.log('123456')
      this.resizeBackgroundColor = '#818181'
      this.resizeCursor = 'col-resize'
      let startX = e.clientX;
      let left = this.$refs.resize.offsetLeft;
      // 鼠标拖动事件
      document.onmousemove = e => {
        var endX = e.clientX;
        var moveLen = left + (endX - startX); // （endx-startx）=移动的距离。resize[i].left+移动的距离=左边区域最后的宽度
        var maxT = this.$refs.box.clientWidth - this.$refs.resize.offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度

        if (moveLen < 32) moveLen = 32; // 左边区域的最小宽度为32px
        if (moveLen > maxT - 500) moveLen = maxT - 500; //右边区域最小宽度为150px

        // resize[i].style.left = moveLen; // 设置左侧区域的宽度
        this.resizeLeft = moveLen + 'px'; // 设置左侧区域的宽度

        // for (let j = 0; j < left.length; j++) {
        //   left[j].style.width = moveLen + "px";
        //   mid[j].style.width = box[i].clientWidth - moveLen - 10 + "px";
        // }
        this.leftWidth = moveLen + "px";
        // this.rightWidth = 
      }
    },
    onResizeMouseUp (e) {
      this.resizeBackgroundColor = '#d6d6d6'
      // this.resizeCursor = 'auto'
      document.onmousemove = null;
    },
    changeIframeDivStyle(display) {
      this.resizeBackgroundColor = '#818181'
      // var iframeDiv = document.getElementsByClassName("iframeDiv");
      // iframeDiv[0].style.display = display;
    },
    competitionWorkspace () {
      competitionWorkspace().then(val => {
				console.log('suc!', val)
        this.iframeUrl = val.data
				// this.$message(val.message)
			}, response => {
        console.log('fail!', response)
				// this.redirectToLogin(response)
			})
    }
  },
  computed: {
    rightWidth() {
      return 'calc(100% - 10px - ' + this.leftWidth + ' )'
    }
  },
  mounted() {
    // this.dragControllerDiv();
    // this.changeIframeDivStyle("none");
  },
  created() {
    this.competitionWorkspace()
    // let MardownIt = require("markdown-it")
    // let md = new MardownIt()
    // let result = md.render(this.markdown)
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.left {
  // width: calc(32% - 10px);
  // height: 100%;
  // background: #ffffff;
  // float: left;
  flex: 2 0 auto;
  position: relative;
  height: 100%;
  overflow: scroll;
  padding: 15px 5px 5px 15px;
}
.resize {
  // float: left;
  // position: relative;
  // top: 45%;
  background-color: #d6d6d6;
  border-radius: 5px;
  // margin-top: -10px;
  width: 10px;
  height: 100%;
  background-size: cover;
  background-position: center;
  /*z-index: 99999;*/
  font-size: 3em;
  color: white;

  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  // background: #111c24;
}
.resize-button {
  height: 55px;
}
.resize:hover {
  cursor: col-resize;
}
.right {
  // float: left;
  // width: 68%;
  // height: 100%;
  // background: #fff;
  // box-shadow: -1px 4px 5px 3px rgba(0, 0, 0, 0.11);
  // min-width: 500px;
}
.iframeDiv {
  // width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1111;
  filter: alpha(opacity=0);
  opacity: 0;
  background: transparent;
  margin-top: 30px;
  /*display: none;*/
}
.md-body {
  text-align: left;
  overflow: auto;
}
</style>
