import Vue from 'vue'
import VueRouter from "vue-router"; 
import Home from '../views/home/Home'
import Auth from '../views/auth/Auth'
import Activation from '../views/auth/Activation'
import FindPassword from '../views/auth/FindPassword'
// import Dashboard from '../views/dashboard/Dashboard'
import DashboardHome from '../views/dashboard/DashboardHome'
import Course from '../views/course/Course'
import Library from '../views/library/Library'
import Competition from '../views/competition/Competition'
import CompetitionHome from '../views/competition/CompetitionHome'
import About from '../views/about/About'
import { Message } from 'element-ui';
// import Layout from '../layout/index.vue';
// import Layout from '@/layout'

Vue.use(VueRouter)

// const Home = () => import('views/home/Home')

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/auth/activate/:token',
    component: Activation,
  },
  {
    path: '/auth/findpassword',
    component: FindPassword,
  },
  {
    path: '/auth/:operation',
    component: Auth,
  },
  // {
  //   path: '/dashboard2',
  //   redirect: '/dashboard2/home',
  //   component: Dashboard,
  //   children: [
  //     {
  //       name: 'dashboard-home',
  //       path: 'home',
  //       component: DashboardHome,
  //     }
  //   ]
  // },
  {
    path: '/course',
    component: Course,
  },
  {
    path: '/library',
    component: Library,
  },
  // {
  //   path: '/dashboard',
  //   component: Layout,
  //   redirect: '/dashboard/home',
  //   children: [{
  //     path: 'home',
  //     name: 'dashboard-home',
  //     // component: () => import('@/views/dashboard/index'),
  //     component: DashboardHome,
  //     meta: { title: 'My Workspaces', icon: 'dashboard' }
  //   }]
  // },
  {
    path: '/dashboard',
    redirect: '/dashboard/home',
  },
  {
    path: '/dashboard/home',
    component: DashboardHome,
  },
  // {
  //   path: '/competition',
  //   redirect: '/competition/home',
  // },
  // {
  //   path: '/competition/home',
  //   component: CompetitionHome,
  // },
  // {
  //   path: '/competition/competition',
  //   component: Competition,
  // },
  {
    path: '/about',
    component: About,
  },
]
 
const originalReplace = VueRouter.prototype.replace
const originalPush = VueRouter.prototype.push
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// const router = new VueRouter({
//   routes
// })
const whiteList = ["/home", "/auth/login", "/auth/register", "/auth/findpassword", "/course", "/library", "/about"];

const createRouter = () => new VueRouter({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

router.beforeEach((to, from, next) => {
  // 判断当前路由是否需要进行权限控制，如果可以放行就调用next函数
  // console.log('from', from)
  // console.log('to', to)
  // console.log('router.app.$options.store.getters.IsLogin', router.app.$options.store.getters.IsLogin)
  // console.log('to', to, router.app.$options.store.getters.IsLogin)
  if (router.app.$options.store.getters.IsLogin) {
    if (to.path.startsWith('/auth/register') || to.path.startsWith('/auth/login')) {
      next({
        path: "/dashboard/home",
      });
    } else{
      next();
    }
  } else {
    // console.log('!!!!', to.path, to.path.startsWith('/auth/activate'))
    if ((whiteList.indexOf(to.path) !== -1) || to.path.startsWith('/auth/activate')) {
      next();
    } else {
      Message({
        // message: this.$t('auth.pleaseLogin'),
        message: Vue.prototype.$bus.$t('auth.pleaseLogin'),
        offset: 65,
        showClose: true,
        duration: 2000,
      })
      next({
        path: "/auth/login",
        query: {
          redirect: to.redirectedFrom
        }
      });
    }
  }
})

export default router