<template>
  <div id="top-bar">
    <!-- <div id="top-nav" :class="topNavClass"> -->
    <div id="top-nav" class="top-nav-full">
      <router-link to="/home">
        <img src="../assets/img/isabelle-cloud.png" alt="logo" height="58px" style="background-color:white; position:relative; top:4px;">
        <!-- <span class="title">{{$t('topbar.title')}}</span> -->
      </router-link>
      <ul class="top-list">
        <li class="top-list-item">
          <router-link to="/home" active-class="a-hover">{{$t('topbar.home')}}</router-link>
        </li>
        <li class="top-list-item">
          <router-link to="/dashboard" active-class="a-hover">{{$t('topbar.dashboard')}}</router-link>
          <!-- <div v-else @click="!showUserMenu">Dashboard</div> -->
          <!-- <router-link v-else to="/dashboard" active-class="a-hover">Dashboard</router-link> -->
        </li>
        <li class="top-list-item">
          <router-link to="/library" active-class="a-hover">{{$t('topbar.library')}}</router-link>
        </li>
        <li class="top-list-item">
          <router-link to="/course" active-class="a-hover">{{$t('topbar.course')}}</router-link>
        </li>
        <!-- <li class="top-list-item">
          <router-link to="/competition" active-class="a-hover">Competition</router-link>
        </li> -->
        <li class="top-list-item">
          <router-link to="/about" active-class="a-hover">{{$t('topbar.about')}}</router-link>
        </li>
      </ul>
    </div>
    <!-- <div class="top-user-menu" v-show="showUserMenu">
      <div class="top-user-menu-grid">
        <div class="logout" @click="logout">
          Logout
        </div>
      </div>
    </div> -->
    <div class="right-menu">
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper" style="margin-right:1em;">
          <span>{{$t('topbar.language')}}</span>
          <i class="el-icon-caret-bottom" />
        </div>
        
      
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <el-dropdown-item @click.native="changeLangauge('zh')" v-show="$i18n.locale != 'zh'">
            <span style="display:block;">简体中文</span>
          </el-dropdown-item>
          <el-dropdown-item @click.native="changeLangauge('en')" v-show="$i18n.locale != 'en'">
            <span style="display:block;">English</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div class="right-menu" v-show="$store.getters.IsLogin">
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <!-- <img :src="avatar+'?imageView2/1/w/80/h/80'" class="user-avatar"> -->
          <span>{{$store.getters.CurrentUser.user_name}}</span>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <router-link to="/">
            <el-dropdown-item>
              {{$t('topbar.home')}}
            </el-dropdown-item>
          </router-link>
          <!-- <a target="_blank" href="https://github.com/PanJiaChen/vue-admin-template/">
            <el-dropdown-item>Github</el-dropdown-item>
          </a>
          <a target="_blank" href="https://panjiachen.github.io/vue-element-admin-site/#/">
            <el-dropdown-item>Docs</el-dropdown-item>
          </a> -->
          <el-dropdown-item divided @click.native="changePassword">
            <span style="display:block;">{{$t('topbar.changePassword')}}</span>
          </el-dropdown-item>
          <el-dropdown-item @click.native="logout">
            <span style="display:block;">{{$t('topbar.logout')}}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <ul class="top-list">
      <li class="top-list-item" v-show="!$store.getters.IsLogin">
        <router-link to="/auth/login" v-show="!isInLogin">{{$t('topbar.login')}}</router-link>
        <router-link to="/auth/register" v-show="!isInRegister">{{$t('topbar.register')}}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'TopBar',
  data () {
    return {
      showUserMenu: false,
      language: 'zh',
      // isHome: false,
    }
  },
  methods: {
    logout() {
      // console.log('logout')
      this.$store.commit('user/Logout')
      // console.log(this.$route.path)
      if (this.$route.path === '/auth/findpassword') {
        this.$router.push('/home')
      } else {
        this.$router.go(0)
      }
    },
    showLangauge() {
      const currentLang = window.sessionStorage.getItem('cloud-lang') || 'zh'
      this.language = currentLang;
    },
    changeLangauge(lang) {
      this.$i18n.locale = lang
      window.sessionStorage.setItem('cloud-lang', lang)
      // window.location.reload()
    },
    changePassword() {
      this.$router.push(`/auth/findpassword`)
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar'
    ]),
    isInLogin() {
      return this.$route.path === '/auth/login'
    },
    isInRegister() {
      return this.$route.path === '/auth/register'
    },
    isInAuth() {
      return this.isInLogin || this.isInRegister
    },
    // isHome() {
    //   return this.$route.path === '/home'
    // },
    // topNavClass() {
    //   return this.isHome ? 'top-nav-full' : 'top-nav-partial'
    // }
  },
  created() {
    this.showLangauge()
  },
  mounted() {
    this.$bus.$off('HideTopBarUserMenu')
    this.$bus.$on('HideTopBarUserMenu', () => {
      this.showUserMenu = false
    })
  }
}
</script>

<style lang="scss" scoped>
#top-bar {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: rgb(15, 14, 14); */
  background: rgb(250, 250, 250);
  border-bottom: 1px solid rgb(200, 200, 200);
  position: relative;
}
.top-nav-full {
  width: 1081px;
  max-width: 90%;
}
// .top-nav-partial {
//   width: 90%;
// }
#top-nav {
  margin: auto;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
}
.title {
  position: relative;
  top: -0.3em;
  font-size: 1.5rem;
  font-family: Inter;
  margin-bottom: 20px;
  /* letter-spacing: -0.025em; */
  line-height: 100%;
  font-family: "TWKEverett", sans-serif;
  color: rgb(0, 0, 0);
  padding: 0px;
  margin: 0px 1em 1.5rem 0.5em;
  /* margin-bottom: 1.5rem; */
  font-weight: normal;
}
.top-list {
  list-style: none;
  position: relative;
  /* margin-left: -30px; */
  /* padding-left: 10px; */
  left: -35px;
  display: flex;
  align-items: center;
  
}
.top-list-item {
  margin-right: 3rem;
  font-weight: 400;
  word-wrap: break-word;
  font-kerning: normal;
  color: rgb(153, 153, 153);
}
/* .top-list-item:hover {
  color: white;
  cursor: pointer;
  transition: all 200ms ease 0s;
} */
a {
  background: transparent;
  border: none;
  /* cursor: pointer; */
  text-decoration: none;
  color: rgb(153, 153, 153);
  /* transition: all 200ms ease 0s; */
  outline: none;
  padding: 5px;
}
.a-hover, a:hover, .logout:hover, .user-portrait:hover {
  color: black;
  cursor: pointer;
  transition: all 200ms ease 0s;
}
a > img {
  background-color: black;
  // position: relative;
  // top: 1em;
}
.user-portrait {
  background: transparent none repeat scroll 0% 0%;
  border: medium none;
  cursor: pointer;
  text-decoration: none;
  color: rgb(153, 153, 153);
  transition: all 200ms ease 0s;
  outline: currentcolor none medium;
  padding: 0px;
  margin-bottom: 0;
  user-select: none;
}
.user-portrait img {
  border: 1px solid rgb(36, 36, 36);
  width: 24px;
  height: 24px;
  margin-left: 0.5rem;
  border-radius: 99999px;
  max-width: 100%;
  padding: 0;
  margin: 0;
}
.username {
  position: relative;
  top: -0.5em;
  margin-left: 0.5em;
}
.top-user-menu {
  right: 5px;
  top: 45px;
  width: 100px;
  z-index: 100;
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  padding: .5rem;
  border-width: 1px;
  position: absolute;
}
.top-user-menu-grid {
  /* position: absolute; */
  font-size: .875rem;
  line-height: 1.65rem;
  background-color: rgb(230, 230, 230);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 4px, rgba(0, 0, 0, 0.24) 0px 16px 32px;
  overflow: hidden;
  border: 1px solid rgb(182, 182, 182);
  /* right: 20px; */
}
  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
avatar
    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        // .user-avatar {
        //   cursor: pointer;
        //   width: 40px;
        //   height: 40px;
        //   border-radius: 10px;
        // }

        span {
          font-size: 15px;
          font-weight: 600;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
.hover-pointer {
  span {
    cursor: pointer
  }
}
</style>