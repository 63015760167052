<template>
	<div>
		<h1 v-if="isWaitingActivate && inputEmail !== '' ">注册成功！<br/>一封激活邮件已经发送到{{inputEmail}}，请进行邮箱验证。</h1>
		<!-- <h1 v-else>恭喜，您的账号已经激活。</h1> -->
	</div>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'
import { activation } from '../../network/auth'

export default ({
	name: 'Activation',
	data() {
		return {
			inputEmail: "",
			isWaitingActivate: true,
		}
	},
	created() {
		if (this.$store.getters.CurrentUser.id != null) {
			this.$message({
				offset: 65,
				showClose: true,
				duration: 2000,
				message: this.$t('auth.activateFailLogin'),
			});
			// this.$router.push('/dashboard')
			// this.$store.commit('user/Logout')
			return;
		}
		activation(this.$route.params.token).then(val => {
			// console.log(val)
			this.$message({
				offset: 65,
				showClose: true,
				duration: 2000,
				message: this.$t('auth.activateSuccess'),
			});
			this.$router.push('/auth/login')
		}, response => {
			this.$message({
				message: response.data.message,
				offset: 65,
				showClose: true,
				duration: 2000,
			});
		})
		// if (!(isWaitingActivate && inputEmail !== '')) {
		// 	this.$router.replace('/home')
		// }
	}

})
</script>

<style scoped>

</style>
